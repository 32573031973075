//material components
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

//hooks
import { useDispatchActivateSearch } from "../../context/ActivateSearchProvider";
import { Tooltip } from "@mui/material";

const SearchIconButton = () => {
    const dispatchActivateSearch = useDispatchActivateSearch();
    const handleActivateSearch = () => {
        dispatchActivateSearch({
            type: "turnOn",
        });
    };
    return (
        <Tooltip title="Buscar" arrow>
            <IconButton color="primary" onClick={handleActivateSearch}>
                <SearchIcon />
            </IconButton>
        </Tooltip>
    );
};

export default SearchIconButton;
