import React, { useEffect, useState } from "react";
import useData from "../../../../hooks/useData";
import { getAllNewVideos } from "../../../../actions";
import { withTopPaddingEnhanced } from "../../../../components/Hocs/withTopPadding";
import PaginationMoreNewVideos from "./PaginationMoreNewVideos";

const MoreNewVideos = () => {
    const EnhancedBox = withTopPaddingEnhanced()();
    const [itemsPerPage, setItemsPerPage] = useState(80);
    const [pageNumber, setPageNumber] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: pageNumber,
        pageSize: itemsPerPage,
    });

    let newsFilter = "aprobado";

    const fetchData = async () => {};
    const { data: newVideos } = useData(
        ["/videos-new", paginationModel, newsFilter],
        getAllNewVideos,
    );
    useEffect(() => {
        fetchData();
    }, []);
    
    return (
        <EnhancedBox
            sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "column",
                padding: {
                    xl: "130px 5rem 40px 5rem",
                    lg: "130px 3.6rem 40px 3.6rem",
                    xs: "50px 1.5rem",
                },
            }}
        >
            <PaginationMoreNewVideos
                dataInformation={newVideos}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber + 1}
                setPaginationModel={setPaginationModel}
            />
        </EnhancedBox>
    );
};

export default MoreNewVideos;
