//Material UI
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
const withTopPadding =
    (topPadding = "130px") =>
    (BaseComponent) => {
        function WithTopPadding({ sx, ...restProps }) {
            const expandedSx = sx ? { ...sx, paddingTop: topPadding } : { paddingTop: topPadding };
            return <BaseComponent sx={expandedSx} {...restProps} />;
        }

        return WithTopPadding;
    };

export default withTopPadding;

export const withTopPaddingEnhanced = (BaseComponent) => (topPadding) => {
    const Component = BaseComponent || Box;
    const paddingTop = topPadding || { xs: "130px !important", md: "80px !important" };
    const EnhancedComponent = styled(Component)(({ theme }) =>
        theme.unstable_sx({
            paddingTop,
        }),
    );
    return EnhancedComponent;
};

export const withTopMarginEnhanced = (BaseComponent) => (topPadding) => {
    const Component = BaseComponent || Box;
    const marginTop = topPadding || { xs: "130px !important", md: "80px !important" };
    const topValue = topPadding || { xs: "130px", md: "80px" };
    const EnhancedComponent = styled(Component)(({ theme }) =>
        theme.unstable_sx({
            marginTop,
        }),
    );
    return {
        component: EnhancedComponent,
        margin: topValue,
    };
};
