import { Box, useMediaQuery, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useMemo } from "react";

const HeroSkeleton = () => {
    const theme = useTheme();
    const isScreenXL = useMediaQuery(theme.breakpoints.only("xl"));
    const isScreenLG = useMediaQuery(theme.breakpoints.only("lg"));
    const isScreenMD = useMediaQuery(theme.breakpoints.only("md"));
    const isScreenSM = useMediaQuery(theme.breakpoints.only("sm"));
    const getSkeletons = useMemo(
        () =>
            isScreenXL
                ? Array.from({ length: 4 })
                : isScreenLG
                  ? Array.from({ length: 3 })
                  : isScreenMD || isScreenSM
                    ? Array.from({ length: 2 })
                    : Array.from({ length: 1 }),
        [isScreenXL, isScreenLG, isScreenMD, isScreenSM],
    );

    return (
        <Box
            sx={{
                display: "flex",
                gap: "15px",
                justifyContent: "center",
                flexWrap: "wrap",
                width: "100%",
            }}
        >
            {getSkeletons.map(() => {
                return (
                    <Box
                        sx={{
                            width: { xs: "100%", sm: "48.3%", lg: "32%", xl: "24%" },
                            height: { xs: "150px", sm: "266px", lg: "266px" },
                            overflow: "hidden",
                            borderRadius: "5px",
                        }}
                        key={crypto.randomUUID()}
                    >
                        <Skeleton variant="rounded" height="100%" />
                    </Box>
                );
            })}
        </Box>
    );
};

export default HeroSkeleton;
