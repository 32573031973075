import { Outlet } from "react-router-dom";

// @mui/material
import { Box } from "@mui/material";

// own components
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import Player from "../components/Player/Player";
import FloatChat from "../components/FloatChat/FloatChat";

import { useContext, useReducer } from "react";
import {
    carShopReducer,
    ticketsReducer,
    crowdFundingReducer,
    buyingReducer,
    products,
    tickets,
    crowdFunding,
    buying,
} from "../actions/reducers/index";
import useFetchPlaylist from "../hooks/useFetchPlaylist";

//context
import { CarContext, CarDispatchContext } from "../context/CarContext";
import { TicketContext, TicketDispatchContext } from "../context/TicketContext";
import { CrowdFundingContext, CrowdFundingDispatchContext } from "../context/CrowdFundingConext";
import { BuyingContext, BuyingDispatchContext } from "../context/BuyingContext";
import { ActivateSearchProvider } from "../context/ActivateSearchProvider";

//react-redux
import { useSelector } from "react-redux";

// test
import { songs } from "../data/data";
import PlayerProvider from "../context/PlayerProvider";

const Main = () => {
    //const playList = songs.slice(0, 3);
    /* const { data, error } = useFetchPlaylist(
        `${process.env.REACT_APP_BASE_URL}/api/reproduction-list/get-data`,
    );*/
    const [stateProduct, dispatchProduct] = useReducer(carShopReducer, products);
    const [stateTicket, dispatchTicket] = useReducer(ticketsReducer, tickets);
    const [stateCrowdFunding, dispatchCrowdFunding] = useReducer(crowdFundingReducer, crowdFunding);
    const [stateBuying, dispatchBuying] = useReducer(buyingReducer, buying);

    const user = useSelector((state) => state.user);

    //const { playList, addMusic } = useContext(PlayerProvider);
    //addMusic(data.data);
    return (
        <Box
            sx={{
                display: "flex",
                minHeight: "100vh",
                flexDirection: "column",
            }}
        >
            <CarContext.Provider value={stateProduct}>
                <CarDispatchContext.Provider value={dispatchProduct}>
                    <TicketContext.Provider value={stateTicket}>
                        <TicketDispatchContext.Provider value={dispatchTicket}>
                            <CrowdFundingContext.Provider value={stateCrowdFunding}>
                                <CrowdFundingDispatchContext.Provider value={dispatchCrowdFunding}>
                                    <BuyingContext.Provider value={stateBuying}>
                                        <BuyingDispatchContext.Provider value={dispatchBuying}>
                                            <ActivateSearchProvider>
                                                <Navbar />
                                            </ActivateSearchProvider>
                                            {/*<FloatChat />*/}
                                            {/*user?.role_name !== "Editor" ? <Player /> : ""*/}
                                            <Outlet />
                                            <Footer />
                                        </BuyingDispatchContext.Provider>
                                    </BuyingContext.Provider>
                                </CrowdFundingDispatchContext.Provider>
                            </CrowdFundingContext.Provider>
                        </TicketDispatchContext.Provider>
                    </TicketContext.Provider>
                </CarDispatchContext.Provider>
            </CarContext.Provider>
        </Box>
    );
};

export default Main;
