import React from "react";

import { Box, Pagination } from "@mui/material";
import VideoNewsCard from "../../../../components/NewsCard/VideoNewsCard";
import HeroSkeleton from "../../../../components/Carousel/HeroSkeleton";

const PaginationMoreNewVideos = ({
    dataInformation = [],
    pageNumber,
    setPaginationModel,
    setPageNumber,
}) => {
    const handlePageChange = (event, value) => {
        const page = value - 1;
        setPageNumber(page);
        setPaginationModel((prevModel) => ({
            ...prevModel,
            page: page,
        }));
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    if (!dataInformation?.data) {
        return <HeroSkeleton />;
    }

    return (
        <Box>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        xs: "1fr 1fr",
                        sm: "1fr 1fr 1fr 1fr",
                        lg: "1fr 1fr 1fr 1fr 1fr",
                        xl: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    },
                    columnGap: "10px",
                    rowGap: "10px",
                    width: "100%",
                }}
            >
                {dataInformation?.data?.map((item, i) => {
                    return <VideoNewsCard item={item} index={i} videoSize="60%" />;
                })}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <Pagination
                    count={dataInformation?.pagination?.pageCount}
                    page={pageNumber}
                    onChange={handlePageChange}
                    color="primary"
                    variant="outlined"
                />
            </Box>
        </Box>
    );
};

export default PaginationMoreNewVideos;
