import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import StorefrontIcon from "@mui/icons-material/Storefront";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import ArticleIcon from "@mui/icons-material/Article";
import SurroundSoundIcon from "@mui/icons-material/SurroundSound";
import TodayIcon from "@mui/icons-material/Today";
import StarsIcon from "@mui/icons-material/Stars";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import LyricsIcon from "@mui/icons-material/Lyrics";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import EmojiSymbolsIcon from "@mui/icons-material/EmojiSymbols";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import SchoolIcon from "@mui/icons-material/School";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import CategoryIcon from "@mui/icons-material/Category";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const HeroCard = (props) => {
    const { category } = props;
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
            }}
        >
            {CATEGORYICON[category]}
            <Typography variant="h6" pl="5px" color={"white"}>
                {category}
            </Typography>
        </Box>
    );
};

export default HeroCard;

const CATEGORYICON = {
    "De Nuestra Tienda": <StorefrontIcon sx={{ color: "white" }} />,
    "La nueva música": <MusicVideoIcon sx={{ color: "white" }} />,
    "Últimas Noticias": <ArticleIcon sx={{ color: "white" }} />,
    "Conciertos Online": <SurroundSoundIcon sx={{ color: "white" }} />,
    "Próximos Eventos": <TodayIcon sx={{ color: "white" }} />,
    Artistas: <StarsIcon sx={{ color: "white" }} />,
    "Los que son tendencia aquí": <StarsIcon sx={{ color: "white" }} />,
    Noticias: <ArticleIcon sx={{ color: "white" }} />,
    Opinión: <ArticleIcon sx={{ color: "white" }} />,
    "Tele Revista": <YouTubeIcon sx={{ color: "white" }} />,
    Música: <AudioFileIcon sx={{ color: "white" }} />,
    "La música en tendencia": <AudioFileIcon sx={{ color: "white" }} />,
    "Los álbumes recomendados": <LibraryMusicIcon sx={{ color: "white" }} />,
    "Los Tops de Alta-Voz": <LyricsIcon sx={{ color: "white" }} />,
    "Las playlists de Alta-Voz": <PlaylistAddCheckIcon sx={{ color: "white" }} />,
    "Recomendaciones editoriales": <PlaylistAddIcon sx={{ color: "white" }} />,
    "Los sencillos con video": <MusicVideoIcon sx={{ color: "white" }} />,
    "Descubrir música": <EmojiSymbolsIcon sx={{ color: "white" }} />,
    "Lo más destacado": <LabelImportantIcon sx={{ color: "white" }} />,
    "Los cursos más populares": <AppShortcutIcon sx={{ color: "white" }} />,
    "Las conferencias magistrales": <SchoolIcon sx={{ color: "white" }} />,
    "Los podcasts  en tendencia": <PodcastsIcon sx={{ color: "white" }} />,
    Categorías: <CategoryIcon sx={{ color: "white" }} />,
    "La biblioteca": <LocalLibraryIcon sx={{ color: "white" }} />,
    "Los instructores destacados": <ManageAccountsIcon sx={{ color: "white" }} />,
};
