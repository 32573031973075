//react
import React, { useState } from 'react'

//redux
import { useDispatch, useSelector } from 'react-redux';
import { changeThemeAction } from "../../redux/slices/userSlice";

//contexts
import { useMode } from '../../context/ModeProvider';
import { useLanguage } from '../../context/LanguageProvider';

//constants
import { DISABLE_CHANGE_THEME, ENABLE_CHANGE_THEME, ONE_CHANGE_THEME, THEME_DARK, THEME_LIGHT } from '../../constants/all';

//mui/material
import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';

//icons
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";


const LightDarkMode = () => {
    const theme = useSelector((state) => state.useThemePage);
    const { languageState } = useLanguage();
    const changeTheme = useSelector((state) => state.changeTheme);
    const { modeState, setModeState } = useMode();
    const dispatchReductAction = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    
        const handleClose = () => {
            setAnchorEl(null);
        };
    
        const handleToggleMode = (typeChange = ENABLE_CHANGE_THEME) => {
            if (changeTheme != DISABLE_CHANGE_THEME) {
                setModeState({ type: "toggle", theme: theme });
                dispatchReductAction(changeThemeAction({ type: typeChange }));
                handleClose();
            }
        };
       
        const handleTheme = () => {
            if (theme == THEME_DARK) {
                setOpenDialog(true);
            } else {
                handleToggleMode();
            }
        };
    
        const handleButtonsDialog = (select) => {
            setOpenDialog(false);
            handleToggleMode(select);
        };
    
        const handleCloseDialog = () => {
            setOpenDialog(false);
        };
    
    
  return (
      <>
          <Tooltip title="Modo Claro/Oscuro" arrow>
              <IconButton onClick={handleTheme} disabled={changeTheme == DISABLE_CHANGE_THEME}>
                  {theme == THEME_LIGHT ? (
                      <DarkModeIcon fontSize="1.5rem" color="primary" />
                  ) : (
                      <LightModeIcon fontSize="1.5rem" color="primary" />
                  )}
              </IconButton>
          </Tooltip>
          <Modal
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <Box
                  sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: "1.3rem 2rem 1rem 2rem",
                  }}
              >
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                      {languageState.texts.Theme.Dialog.Title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {languageState.texts.Theme.Dialog.Ask}
                  </Typography>

                  <Box
                      container
                      sx={{
                          display: "flex",
                          paddingTop: "1rem",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                      }}
                  >
                      <Button
                          sx={{
                              marginLeft: 0,
                              marginRight: "auto",
                          }}
                          onClick={() => handleButtonsDialog(ONE_CHANGE_THEME)}
                      >
                          {languageState.texts.Theme.Dialog.Buttons.OneUse}
                      </Button>

                      <Button
                          sx={{
                              marginLeft: "auto",
                              marginRight: "auto",
                          }}
                          onClick={() => handleButtonsDialog(ENABLE_CHANGE_THEME)}
                      >
                          {languageState.texts.Theme.Dialog.Buttons.Alter}
                      </Button>

                      <Button
                          sx={{
                              marginLeft: "auto",
                              marginRight: 0,
                          }}
                          onClick={() => handleButtonsDialog(DISABLE_CHANGE_THEME)}
                      >
                          {languageState.texts.Theme.Dialog.Buttons.Always}
                      </Button>
                  </Box>
              </Box>
          </Modal>
      </>
  );
}

export default LightDarkMode
