//hooks
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useLanguage } from "../../context/LanguageProvider";
import { useActivateSearch, useDispatchActivateSearch } from "../../context/ActivateSearchProvider";

//actions
import { getSearchResult } from "../../actions";

//utils
import { css } from "@emotion/css";

//components
import {
    FormControl,
    Box,
    Tooltip,
    Autocomplete,
    Typography,
    OutlinedInput,
    Fade,
    Tab,
    Tabs,
    Button,
    InputAdornment,
    IconButton,
    Load,
} from "@mui/material";

//icons
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//styles
import "../../styles/cards.css";

//aux functions
async function fetchNews(key, page = 1) {
    const response = await getSearchResult(key, 10, page);
    if (response.status !== 200) {
        throw new Error("Fetch failed");
    }
    return response.data;
}

//custom component
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            key={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

const SearchBar = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { languageState } = useLanguage();

    const isSearchExpanded = useActivateSearch();
    const dispatchActivateSearch = useDispatchActivateSearch();
    const searchInputRef = useRef(null);
    const [toSearch, setToSearch] = useState("");
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const [optionAutocomplete, setOptionsAutocomplete] = useState([]);
    const [valueTab, setValueTab] = useState(0);
    const [reloading, setReloading] = useState(false);

    const preventDefault = (event) => event.preventDefault();

    const isMoreResult = (pagination) =>
        pagination?.pageCount !== pagination?.page && pagination?.pageCount !== 0;

    useEffect(() => {
        if (searchInputRef.current && isSearchExpanded) searchInputRef.current.focus();
    }, [isSearchExpanded]);
    const handleTooltip = () => {
        setOpenTooltip(true);
        setTimeout(() => setOpenTooltip(false), 3800);
    };
    const loadingAutocomplete = openAutocomplete && optionAutocomplete.length === 0;
    useEffect(() => {
        let active = true;

        if (!loadingAutocomplete) {
            return undefined;
        }

        (async () => {
            if (toSearch.length >= 3) {
                setTimeout(async () => {
                    if (active) {
                        const data = await fetchNews(toSearch);
                        setOptionsAutocomplete([data.data]);
                    }
                }, 1000);
            }
        })();

        return () => {
            active = false;
        };
    }, [loadingAutocomplete, toSearch]);

    const randomGradient = `gradient-${Math.floor(Math.random() * 10) + 1}`;

    useEffect(() => {
        if (!openAutocomplete || toSearch.length < 3) {
            setOptionsAutocomplete([]);
        }
    }, [openAutocomplete, toSearch]);

    useEffect(() => {
        if (toSearch.includes(`"`)) {
            const newValue = toSearch.replace(`"`, "");
            setToSearch(newValue);
        }
    }, [toSearch]);

    const handleToSearch = (e) => {
        setToSearch(e.target.value);
    };
    const handleToSearchAutocomplete = (e, newValue) => {
        setToSearch(newValue);
    };

    const getSearchLink = (params) => {
        const searchParams = new URLSearchParams();
        Object.entries(params).map(([key, value]) => {
            searchParams.append(key, value);
        });
        return `/buscar?${searchParams.toString()}`;
    };

    const actionToSearch = () => {
        if (toSearch.length < 3) {
            handleTooltip();
            return;
        }
        const link = getSearchLink({ title: toSearch });
        navigate(link);
    };

    const handleTabChange = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleNewResult = async (page) => {
        setReloading(true);
        const { data: newData } = await fetchNews(toSearch, page + 1);
        let newResult = optionAutocomplete[0];
        Object.entries(newResult).map(([key, value]) => {
            if (value.length !== 0 && newData[key]?.data?.length !== 0) {
                newResult[key]?.data.push(...newData[key]?.data);
                newResult[key].pagination.page = newData[key].pagination.page;
            }
        });
        setOptionsAutocomplete([newResult]);
        setReloading(false);
    };

    const handleSearchBlur = () => {
        dispatchActivateSearch({
            type: "turnOff",
        });
        setToSearch("");
    };
    return (
        isSearchExpanded && (
            <FormControl
                sx={{
                    div: { borderRadius: "25px" },
                    input: { fontSize: "15px" },
                    width: "100%",
                }}
                variant="outlined"
                component="form"
                onBlur={handleSearchBlur}
                onSubmit={preventDefault}
            >
                <Tooltip
                    open={openTooltip}
                    TransitionComponent={Fade}
                    title={
                        <Typography component="p" sx={{ fontSize: "16px", margin: "5px 8px" }}>
                            Debe introducir al menos 3 caracteres para iniciar una búsqueda
                        </Typography>
                    }
                >
                    <Autocomplete
                        autoFocus
                        freeSolo={true}
                        id="search-bar"
                        disableClearable
                        open={openAutocomplete}
                        inputValue={toSearch}
                        onInputChange={handleToSearchAutocomplete}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                event.stopPropagation();
                                actionToSearch();
                            }
                        }}
                        onOpen={() => {
                            setOpenAutocomplete(true);
                        }}
                        onClose={() => {
                            setValueTab(0);
                            setOpenAutocomplete(false);
                        }}
                        getOptionLabel={(option) => {
                            const objJson = JSON.stringify(option);
                            return objJson;
                        }}
                        placeholder={languageState.texts.Navbar.Search.placeholder}
                        options={optionAutocomplete}
                        loading={loadingAutocomplete}
                        renderInput={(params) => (
                            <OutlinedInput
                                inputProps={{ ...params.inputProps }}
                                ref={params.InputProps.ref}
                                inputRef={searchInputRef}
                                id="search"
                                color="primary"
                                type="text"
                                size="small"
                                autoComplete="off"
                                fullWidth
                                placeholder={languageState.texts.Navbar.Search.placeholder}
                                onChange={handleToSearch}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            color="primary"
                                            type="submit"
                                            aria-label="search"
                                            onClick={actionToSearch}
                                            edge="end"
                                        >
                                            <SearchIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton
                                            color="primary"
                                            type="button"
                                            aria-label="search"
                                            onMouseDown={() => {
                                                handleSearchBlur();
                                            }}
                                            edge="end"
                                        >
                                            <ArrowBackIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        )}
                        renderOption={(props, option) => {
                            const { key } = props;
                            const tabs = [];
                            const optionsTabs = [];
                            let isMore = false;
                            Object.entries(option).map(([key, value], index) => {
                                if (value?.data.length === 0 || key === "tags") {
                                    return;
                                }
                                if (isMoreResult(value?.pagination) && !isMore) {
                                    isMore = true;
                                }
                                tabs.push(
                                    <Tab
                                        key={`simple-tab-${index}`}
                                        label={languageState.texts.PanelEntitiesNames[key]}
                                        id={`simple-tab-${index}`}
                                        aria-controls={`simple-tabpanel-${index}`}
                                    />,
                                );
                                optionsTabs.push(
                                    <CustomTabPanel value={valueTab} index={tabs.length - 1}>
                                        {value?.data?.map((item, i) => {
                                            return (
                                                <a
                                                    key={`tabpanel-${index}-option-${i}`}
                                                    className={css({
                                                        cursor: "pointer",
                                                        textDecoration: "none",
                                                        transition: "all 150ms",
                                                        padding: "10px",
                                                        color: theme.palette.disabled.dark,
                                                        "&:hover": {
                                                            color: theme.palette.primary.main,
                                                        },
                                                    })}
                                                    onClick={() => setOpenAutocomplete(false)}
                                                    href={key === "tele-revista" ? item.url : item.link}
                                                    target="_blank"
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "25% 75%",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            key === "tele-revista"
                                                                ? item?.image_url
                                                                    ? item?.image_url
                                                                    : ""
                                                                : item?.image
                                                                  ? item?.image
                                                                  : ""
                                                        }
                                                        className={randomGradient}
                                                        style={{
                                                            width: "100%",
                                                            height: "80px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                    <Typography
                                                        sx={{ fontSize: "0.9rem", fontWeight: "300" }}
                                                    >
                                                        {item.title
                                                            ? item.title.slice(0, 50)
                                                            : item.name
                                                              ? item.name.slice(0, 50)
                                                              : ""}
                                                        ...
                                                    </Typography>
                                                </a>
                                            );
                                        })}
                                        {value.pagination.pageSize * value.pagination.page <
                                            value.pagination.total &&
                                            isMore && (
                                                <Button
                                                    sx={{ width: "100%", textTransform: "none" }}
                                                    variant="text"
                                                    startIcon={reloading ? "" : <ExpandMoreIcon />}
                                                    disabled={reloading}
                                                    onClick={() => {
                                                        handleNewResult(
                                                            option["news-adder"]?.pagination?.page,
                                                        );
                                                    }}
                                                >
                                                    {reloading ? "cargando..." : "Ver más resultados"}
                                                </Button>
                                            )}
                                    </CustomTabPanel>,
                                );
                            });
                            return (
                                <>
                                    {
                                        <Box>
                                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                <Tabs
                                                    value={valueTab}
                                                    onChange={handleTabChange}
                                                    aria-label="scrollable auto tabs example"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                    {tabs.map((tab) => tab)}
                                                </Tabs>
                                            </Box>
                                            {optionsTabs.map((optionTab) => optionTab)}
                                        </Box>
                                    }
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Button
                                            sx={{ width: "100%", textDecoration: "none" }}
                                            variant="text"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {
                                                setOpenAutocomplete(false);
                                                actionToSearch();
                                            }}
                                        >
                                            Ver todos los resultados
                                        </Button>
                                    </Box>
                                </>
                            );
                        }}
                    />
                </Tooltip>
            </FormControl>
        )
    );
};

export default SearchBar;
